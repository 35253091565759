<template>
  <ul class="educourse-list">
    <li class="list-item">
      <div class="educourse">
        <div class="educourse-summary course-row" :style="hideLine ? {'border-top': 'none'} : {}">
          <article class="course-card">
            <div class="course-content" style="min-height: 0;">
              <div class="content-header" style="margin-top: 16px; margin-bottom: 0;">
                <h5 class="title">
                  <a href="javascript:" class="title-link">진행 및 예정된 연수</a>
                </h5>
              </div>
              <div class="course-total-count">
                <div class="kb-form-search">
                  <div class="kb-form-search-status">
                    <span class="text">총 {{ items.length }}건</span>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
        <template v-if="items.length === 0">
          <div class="search-container">
            <div class="result-empty">
              <img src="../../assets/lxp/images/common/img_empty.png" alt="">
              <p class="text">진행 및 예정된 연수가 없습니다.</p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="educourse-content">
            <div class="kb-table kb-table-round">
              <table>
                <colgroup>
                  <col style="width:100px;">
                  <col style="width:auto;">
                  <col style="width:500px;">
                  <col style="width:160px;">
                </colgroup>
                <thead>
                <tr>
                  <th><span class="th-title">&nbsp;</span></th>
                  <th><span class="th-title">과정차수명</span></th>
                  <th><span class="th-title">연수기간</span></th>
                  <th><span class="th-title">완료여부</span></th>
                </tr>
                </thead>
                <tbody>
                  <template v-for="(item, idx) in items" :key="idx">
                    <tr>
                      <td>
                        <div class="kb-table-image">
                          <img v-if="item.thumb" :src="getThumbUrl(item.thumb)" alt="">
                          <img v-else-if="item.mstThumb" :src="getThumbUrl(item.mstThumb)" alt="">
                          <CourseThumb v-else :num="item.crseMstSn"/>
                        </div>
                      </td>
                      <td>
                        <strong class="td-text kb-mouse-cursor kb-hover-underline" @click="goLearn(item)">{{ item.crseNm }}</strong>
                      </td>
                      <td><strong class="td-text">{{ timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') }} ~ {{ timestampToDateFormat(item.endDt, 'yyyy.MM.dd') }}</strong></td>
                      <td>
                        <strong class="td-text" v-html="getFinishText(item)"></strong>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </div>
    </li>
  </ul>
</template>
<style>
.lxp-layout .course-row .course-total-count {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.kb-table-image img{
  height: 60px;
  width: 60px;
  border-radius: 16px;
}

</style>

<script>

import CourseThumb from '@/components/apply/CourseThumb';
import {trainStarLinkSetup} from "@/assets/js/modules/train/train-star-link-setup";

export default {
  name: "ApplyStarLinkCourse",
  components: {CourseThumb},
  props: {
    items: Array,
    hideLine: Boolean
  },
  setup: trainStarLinkSetup
}
</script>
