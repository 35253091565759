<template>
  <main class="kb-main" id="kb-educourse">
    <lxp-main-header
        :show-title="true"
        :show-breadcrumb="true"
        title="연수신청">
      <template v-slot:inner>
        <div class="header-column">
          <div class="download">
<!--            <a href="javascript:" class="download-link" @click="downGuide">디지털문해력 연수가이드<i class="icon-download"></i></a>-->
<!--            <a href="javascript:" class="download-link" @click="susdyGuide">계열사담당자<i class="icon-download"></i></a>
            <a href="javascript:" class="download-link" @click="goTest">사전테스트<i class="icon-arrow-next"></i></a>-->
          </div>
        </div>
      </template>
    </lxp-main-header>
    <div class="main-content main-component">
      <div class="educourse-list-container">
        <!-- list-top -->
        <article class="content-section section-spacing">
          <header class="section-header" style="justify-content:flex-end">
            <div class="kb-form-dropdown download-dropdown" :class="{'is-active': showAttachments}" data-offset="bottom-right" @click="showAttachments = !showAttachments">
<!--              <button class="kb-form-dropdown-btn kb-btn kb-btn-secondary kb-btn-sm rounded-lg"><i class="icon-download32"></i><span class="text">스타링크아카데미 가이드북</span></button>-->
<!--              <div class="kb-form-dropdown-options">
                <div class="dropdown-option">
                  <ul class="dropdown-option-list" v-for="(file,idx) in files" :key="idx">
                    <li class="dropdown-option-item">
                      <a class="dropdown-option-link" :title="file.fileNm" @click="downloadAtch(file)">
                        <span type="button" class="dropdown-option-text">{{ (file.fileNm != null ? file.fileNm : '-') }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>-->
            </div>
          </header>
          <header class="section-header">
            <h4 class="title">연수현황</h4>
          </header>
          <div class="kb-table kb-table-bordered-v2">
            <table>
              <colgroup>
              </colgroup>
              <thead>
                <tr>
                  <th><span class="th-title">Lv.0</span></th>
                  <th><span class="th-title">Lv.1</span></th>
                  <th><span class="th-title">Lv.2</span></th>
                  <th><span class="th-title">Lv.3</span></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong class="td-text">{{ dtlItem && dtlItem.lv0Fnsh ? dtlItem.lv0Fnsh : '-' }}</strong></td>
                  <td><strong class="td-text">{{ dtlItem && dtlItem.lv1Fnsh ? dtlItem.lv1Fnsh : '-' }}</strong></td>
                  <td><strong class="td-text">{{ dtlItem && dtlItem.lv2Fnsh ? dtlItem.lv2Fnsh : '-' }}</strong></td>
                  <td><strong class="td-text">{{ dtlItem && dtlItem.lv3Fnsh ? dtlItem.lv3Fnsh : '-' }}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </article>

        <div class="list-top">
          <div class="top-column"></div>
          <div class="top-column"></div>
        </div>
        <template v-if="isReady">
          <div>
            <ApplyStarLinkCourse
              :items="renderItems"
              :hide-line="true"
            />
          </div>
        </template>

        <div class="educourse">
          <div class="educourse-summary course-row" style="border-top: none">
            <article class="course-card">
              <div class="course-content" style="min-height: 0;">
                <div class="content-header" style="margin-top: 16px; margin-bottom: 0;">
                  <h5 class="title">
                    <a href="javascript:" class="title-link">연수과정</a>
                  </h5>
                </div>
                <div class="course-total-count">
                  <div class="kb-form-search">
                    <div class="kb-form-search-status">
                      <span class="text">총 {{ aplyItems.length }}건</span>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <template v-if="aplyItems.length === 0">
          <div class="search-container">
            <div class="result-empty">
              <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
              <p class="text">연수과정이 없습니다.</p>
            </div>
          </div>
        </template>
        <template v-else>
          <ul class="educourse-list">
            <li v-for="(item, index) in aplyItems" :key="index" class="list-item">
              <div class="educourse">
                <div class="educourse-summary course-row" style="padding-bottom: 10px;cursor: pointer" @click="collapseOrExpand(index)">
                  <article class="course-card">
                    <div class="course-image">
                      <figure class="image-figure">
                        <img v-if="item.thumb" :src="getThumbUrl(item.thumb)" alt="">
                        <CourseThumb
                            v-else
                            :num="item.crseMstSn"
                        />
                      </figure>
                    </div>
                    <div class="course-content">
                      <div class="content-header">
                        <h5 class="title">
                          {{ item.crseNm }}
                        </h5>
                      </div>
                      <div :class="{'content-footer': isMobile}">
                        <div class="content-meta">
                          <span class="text" :class="{'txt_line' : !isMobile, 'txt_multi' : isMobile}" style="color:var(--kb-gold)">{{ item.crseSumup }}</span>
                        </div>
                        <div class="content-meta" style="margin-top: 20px">
                          <span class="text">
                            {{ item.orgnzNm }}
                            {{ item.totLrnPerid ? ' | '.concat(item.totLrnPerid,'일') : '' }}
                            {{ item.crseMlg ? ' | 마일리지'.concat(item.crseMlg) : '' }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="course-actions" style="display: flex;justify-content: flex-end;flex-direction: column;margin-left: 10px">
                      <div></div>
                      <div v-if="!isMobile" class="striped-list-wrap">
                        <ul class="striped-list">
                          <li class="striped-item">
                            <div class="kb-collapse" :class="{ 'is-active' : !showDists[index] }">
                              <div class="kb-collapse-toggle striped-row" :style="showDists[index] ? {'background-color':'lightgray'} : {}">
                                <div class="striped-column column-arrow"><i class="icon-arrow"></i></div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </article>
                </div>

                <template v-if="showDists[index]">
                  <div v-if="item.crseAplyDomains && item.crseAplyDomains.length > 0" class="educourse-content">
                    <div class="kb-table kb-table-round">
                      <table>
                        <colgroup>
                          <col style="width:auto;">
                          <col style="width:280px;">
                          <col style="width:280px;">
                          <col style="width:150px;">
                          <col style="width:140px;">
                        </colgroup>
                        <thead>
                        <tr>
                          <th><span class="th-title">과정차수명</span></th>
                          <th><span class="th-title">연수기간</span></th>
                          <th><span class="th-title">신청기간</span></th>
                          <th><span class="th-title">정원</span></th>
                          <th><span class="th-title">&nbsp;</span></th>
                        </tr>
                        </thead>
                        <tbody>
                          <template v-for="(dist, idx) in item.crseAplyDomains[0].crseDists" :key="idx">
                            <tr v-if="isShowApply(dist)" style="cursor: pointer" @click="showApplyView(dist.distCrseSn)">
                              <td><strong class="td-text">{{ dist.crseNm }}</strong></td>
                              <td><strong class="td-text">{{ timestampToDateFormat(dist.bgngDt, 'yyyy.MM.dd') }} ~ {{ timestampToDateFormat(dist.endDt, 'yyyy.MM.dd') }}</strong></td>
                              <td>
                                <strong v-if="dist.aplyBgngDt" class="td-text">{{
                                  timestampToDateFormat(dist.aplyBgngDt, 'yyyy.MM.dd')
                                  }} ~ {{ timestampToDateFormat(dist.aplyEndDt, 'yyyy.MM.dd') }}</strong>
                                <strong v-else class="td-text">-</strong>
                              </td>
                              <td><strong class="td-text">{{ dist.peoplCnt > 0 ? `${numberComma(dist.peoplCnt)}명` : '-' }}</strong></td>
                              <td class="text-end">
                                <router-link :to="{name: 'ApplyTrainView', params: {distCrseSn: dist.distCrseSn}}" class="td-link">
                                  <template v-if="limitOver(dist)">
                                    <strong class="td-text" style="color: red">정원초과</strong>
                                  </template>
                                  <template v-else>
                                    <strong class="td-text">상세</strong><i class="icon-arrow"></i>
                                  </template>
                                </router-link>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div v-else>
                    <div class="kb-table kb-table-round">
                      <table>
                        <colgroup>
                            <col style="width:auto;">
                            <col style="width:280px;">
                            <col style="width:280px;">
                            <col style="width:150px;">
                            <col style="width:140px;">
                        </colgroup>
                        <thead>
                        <tr>
                            <th><span class="th-title">과정차수명</span></th>
                            <th><span class="th-title">연수기간</span></th>
                            <th><span class="th-title">신청기간</span></th>
                            <th><span class="th-title">정원</span></th>
                            <th><span class="th-title">&nbsp;</span></th>
                        </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center" colspan="5"><strong class="td-text">수강신청 가능한 차수가 없습니다.</strong></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>
              </div>
            </li>
          </ul>
        </template>
        <div class="ref-list-container">
          <div class="educourse">
            <div class="educourse-summary course-row" style="border-top: none">
              <article class="course-card">
                <div class="course-content" style="min-height: 0;">
                  <div class="content-header" style="margin-top: 16px; margin-bottom: 0;">
                    <h5 class="title">
                      <a href="javascript:" class="title-link">자료실</a>
                    </h5>
                  </div>
                </div>
              </article>
            </div>
          </div>
          <div class="list-top">
            <div class="top-column ps-6">
              <p class="title text-muted"></p>
            </div>
            <div class="top-column">
              <div class="kb-form-search">
                <p class="title text-muted">총 {{ paging.totalCount }}건</p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="kb-form-search-field">
                  <input type="text" class="kb-form-search-input" v-model="search.title" @keyup.enter="getStarLinkFiles" placeholder="자료실 검색" />
                  <button class="kb-form-search-btn" @click="getStarLinkFiles"><i class="icon-search">검색</i></button>
                </div>
              </div>
            </div>
          </div>

          <div class="board-list-container">
            <template v-if="fileItems.length === 0">
              <div class="search-container">
                <div class="result-empty">
                  <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
                  <p class="text">자료실에 등록된 파일이 없습니다.</p>
                </div>
              </div>
            </template>
            <template v-else>
              <ul class="board-list">
                <li v-for="(item, idx) in fileItems" class="board-list-item" :key="idx">
                  <article class="board-row">
                    <router-link :to="{ name: 'LearnBulletinView', params: {mode : 'view', lrnPostSn: item.lrnPostSn} }" class="board-link"></router-link>
                    <div class="board-column column-index">{{idx+1+(paging.pageSize*(paging.pageNo-1))}}</div>
                    <div class="board-column column-file"><a href="javascript:" class="file-link" v-if="item.postFileAtchYn === 'Y'"><i class="icon-file"></i></a></div>
                    <div class="board-column column-title"><span class="title">{{item.title}}</span><i v-if="item.newLib === 'Y'" class="icon-new"></i></div>
                    <div class="board-column column-date"><span class="text">{{timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm')}}</span></div>
                    <div class="board-column column-hit"><span class="text">{{item.inqCnt}} 조회</span></div>
                  </article>
                </li>
              </ul>
              <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>

import LxpMainHeader from '@/components/common/LxpMainHeader';
import CourseThumb from '@/components/apply/CourseThumb';
import {trainStarLinkSetup} from "@/assets/js/modules/train/train-star-link-setup";
import ApplyStarLinkCourse from "@/components/apply/ApplyStarLinkCourse.vue";
import CommonPaginationFront from "@/components/CommonPaginationFront.vue";

export default {
  name: 'TrainStarLink',
  components: {CommonPaginationFront, ApplyStarLinkCourse, LxpMainHeader, CourseThumb},
  setup: trainStarLinkSetup
};
</script>

<style scoped>

.lxp-layout .course-row .course-total-count {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.kb-table-image img {
  height: 60px;
  width: 60px;
  border-radius: 16px;
}

</style>